import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HealthCheckService } from '../services/health-check.service';

@Injectable({
  providedIn: 'root'
})
export class CheckServerStatusInterceptor implements HttpInterceptor {
  constructor(private router: Router, private healthCheckService: HealthCheckService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const healtCheckApiUrl = this.healthCheckService.getHealthCheckApiUrl();

    return next.handle(request).pipe(
      catchError((requestError: HttpErrorResponse) => {
        // For preventing infinite checkIsServerDown API call
        if (request.url.includes(healtCheckApiUrl)) {
          return throwError(requestError);
        }

        return this.checkIsServerDown(request, next, requestError);
      })
    );
  }

  private checkIsServerDown(
    request: HttpRequest<any>,
    next: HttpHandler,
    interceptReqError: HttpErrorResponse
  ): Observable<any> {
    return this.healthCheckService.livenessStatus().pipe(
      catchError(() => {
        this.router.navigate(['/server-is-not-available'], {
          queryParamsHandling: 'merge'
        });

        return throwError(interceptReqError);
      }),
      switchMap(() => {
        return next.handle(request);
      })
    );
  }
}
