<div class="popup">
  <button mat-icon-button class="popup__close-btn" mat-dialog-close>
    <mat-icon class="popup__close-btn--icon">close</mat-icon>
  </button>
  <div mat-dialog-content class="popup__inner-container">
    <div class="content">
      <img src="./assets/images/streetwear-logo-horizontal.png" alt="" class="merkur-streetwear-logo" />
      <p [innerHTML]="'streetwear_popup_text' | translate" class="info-text-container"></p>
      <div #qrCodeContainer class="qr-code-container"></div>
    </div>
  </div>
</div>
