import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProjectConfigService } from '@app/shared/services/project-config.service';
import { Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataFetchService {
  environment: any;

  allSubscriptions: Subscription[] = [];
  apiKey: string | null = null;

  constructor(public http: HttpClient, public config: ProjectConfigService) {
    this.environment = this.config.getConfig();
  }

  get<T>(url = '', options?: Record<string, never>): Observable<any> {
    return this.http.get<T>(url, options);
  }

  post<T>(url = '', payload = {}): Observable<any> {
    return this.http.post<T>(url, { ...payload });
  }

  patch<T>(url: string, payload: any): Observable<T> {
    return this.http.patch<T>(url, payload);
  }
  unsubscribe(): void {
    for (const subscription of this.allSubscriptions) {
      if (!subscription.closed) {
        subscription.unsubscribe();
      }
    }
  }

  specialGet<T>(url = ''): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get(url, { headers, responseType: 'text' });
  }
}
