import { Pipe, PipeTransform, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '@app/modules/layout/services/theme.service';
import { Subscription } from 'rxjs';

@Pipe({
  name: 'translate',
  pure: false
})
export class DynamicTranslatePipe implements PipeTransform, OnDestroy {
  private currentValue = '';
  private lastKey = '';
  private langChangeSub: Subscription;

  constructor(
    private translateService: TranslateService,
    private themeService: ThemeService,
    private cdr: ChangeDetectorRef
  ) {
    this.langChangeSub = this.translateService.onLangChange.subscribe(() => {
      this.updateValue(this.lastKey);
      this.cdr.markForCheck();
    });
  }

  transform(value: string, params?: any): string {
    if (!value) {
      return;
    }
    if (value !== this.lastKey) {
      this.lastKey = value;
      this.updateValue(value, params);
    }
    return this.currentValue;
  }

  private updateValue(key: string, params?: any): void {
    if (!key) {
      return;
    }
    const dynamicKey = this.verifyTag(key);
    this.translateService.get(dynamicKey, params).subscribe(translatedValue => {
      if (this.currentValue !== translatedValue) {
        this.currentValue = translatedValue;
        this.cdr.markForCheck();
      }
    });
  }

  verifyTag(key: string): string {
    const keyMapping = this.themeService.getThemeTranslationTags();
    return keyMapping ? keyMapping[key] || key : key;
  }

  ngOnDestroy(): void {
    if (this.langChangeSub) {
      this.langChangeSub.unsubscribe();
    }
  }
}
