import { Component, OnInit } from '@angular/core';
import { LAST_ONLINE_STATE_INTERVAL_IN_MS } from '@app/modules/registration/utils/constants';
import { HealthCheckService } from '@app/shared/services/health-check.service';
import { NotificationService } from '@app/shared/services/notification.service';
import { TerminalDataService } from '@app/shared/services/terminal-data.service';
import { marker as __ } from '@biesbjerg/ngx-translate-extract-marker';
import { Observable, Subscription, fromEvent, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-internet-connection-status',
  template: ''
})
export class InternetConnectionStatusComponent implements OnInit {
  private connectionOnlineStatusMessage: string = __('connection_online_status_message');
  private connectionOfflineStatusMessage: string = __('connection_offline_status_message');
  private onlineEvent: Observable<Event>;
  private offlineEvent: Observable<Event>;
  private subscriptions: Subscription[] = [];

  constructor(
    private notificationService: NotificationService,
    private healthCheckService: HealthCheckService,
    private terminalDataService: TerminalDataService
  ) {}

  ngOnInit(): void {
    this.listenConnectionStatus();
    this.subscriptions.push(
      this.terminalDataService.isTerminalDataSet.subscribe(loaded => {
        if (loaded) {
          this.updateLastOnlineTime();
        }
      })
    );
  }

  /**
   * Get the online/offline status from browser window
   */
  listenConnectionStatus(): void {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(
      this.onlineEvent.subscribe(() => {
        this.notificationService.showMessage(this.connectionOnlineStatusMessage, 'success', false, 3000);
        this.healthCheckService.setInternetStatus(true);
      })
    );

    this.subscriptions.push(
      this.offlineEvent.subscribe(() => {
        this.notificationService.showMessage(this.connectionOfflineStatusMessage, 'error', false);
        this.healthCheckService.setInternetStatus(false);
      })
    );
  }

  updateLastOnlineTime(): void {
    this.subscriptions.push(
      timer(0, LAST_ONLINE_STATE_INTERVAL_IN_MS)
        .pipe(mergeMap(() => this.terminalDataService.getTerminalLastOnlineState()))
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
