import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProjectConfigService } from '@app/shared/services/project-config.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { delay, retryWhen } from 'rxjs/operators';
import { LivenessStatus } from '../models/healt-check';

@Injectable({
  providedIn: 'root'
})
export class HealthCheckService {
  private environment: any;
  private hasInternetConnection = true;

  constructor(
    private http: HttpClient,
    public projectConfigService: ProjectConfigService,
    public activatedRoute: ActivatedRoute
  ) {
    this.environment = this.projectConfigService.getConfig();
  }

  livenessStatus(): Observable<LivenessStatus> {
    const apiUrl = this.getHealthCheckApiUrl();
    return this.http.get<LivenessStatus>(apiUrl);
  }

  livenessStatusRetryUntilSuccess(delayInMS = 5000): Observable<LivenessStatus> {
    return this.livenessStatus().pipe(retryWhen(errors => errors.pipe(delay(delayInMS))));
  }

  public getHealthCheckApiUrl(): string {
    return `${this.environment.hostUrlDolphin}/${this.environment.healthCheck}`;
  }

  public setInternetStatus(isOnline: boolean): void {
    this.hasInternetConnection = isOnline;
  }

  public getInternetStatus(): boolean {
    return this.hasInternetConnection;
  }
}
