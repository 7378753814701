import { Component, OnDestroy, OnInit } from '@angular/core';
import { HealthCheckService } from '@app/shared/services/health-check.service';
import { PageReloadService } from '@app/shared/services/page-reload.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/shared/services/auth.service';
import { LivenessStatus, LivenessStatusResponse } from '@app/shared/models/healt-check';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { marker as __ } from '@biesbjerg/ngx-translate-extract-marker';
import { PreloadService } from '@app/shared/services/preload.service';
import { Theme } from '@themes/models/theme';
import { ThemeService } from '../layout/services/theme.service';
import { ServerIsNotAvailableErrorPageIcons } from './utilis/base64-icons';

/**
 * Display an error page if the server is not available.
 *
 * Note: Do not extend this class with BaseComponent class. To prevent infinite reload page. Because the BaseComponent class implements screenInactive logic.
 */
@Component({
  selector: 'app-server-is-not-available-error-page',
  templateUrl: './server-is-not-available-error-page.component.html',
  styleUrls: ['./server-is-not-available-error-page.component.scss']
})
export class ServerIsNotAvailableErrorPageComponent implements OnInit, OnDestroy {
  public homeImgSrc: SafeUrl | string = '';
  public errorImgSrc: SafeUrl | string = '';
  public errorMsg = '';
  theme: Theme;
  public matRippleColor = 'rgb(255,255,255,30%)';
  private allSubscriptions: Subscription[] = [];

  constructor(
    public _route: ActivatedRoute,
    public pageReload: PageReloadService,
    public authService: AuthService,
    private healthCheckService: HealthCheckService,
    private domSanitizer: DomSanitizer,
    private preloadService: PreloadService,
    private themeService: ThemeService
  ) {}

  ngOnInit(): void {
    this.getIcons();
    this.setErrorMessage();
    this.checkIsServerDown();
    this.getTheme();
  }

  getTheme(): void {
    const themeSub = this.themeService.getTheme().subscribe(theme => (this.theme = theme));
    this.allSubscriptions.push(themeSub);
  }

  getIcons(): void {
    const { HOME_ICON, ERROR_ICON } = ServerIsNotAvailableErrorPageIcons;
    this.homeImgSrc = this.domSanitizer.bypassSecurityTrustUrl(HOME_ICON);
    this.errorImgSrc = this.domSanitizer.bypassSecurityTrustUrl(ERROR_ICON);
  }

  setErrorMessage(): void {
    const initialTranslations = this.preloadService.getTranslationsFromRequest();
    this.errorMsg = initialTranslations?.server_is_not_available_error_msg || '';
  }

  checkIsServerDown(): void {
    const healthCheckSub: Subscription = this.healthCheckService
      .livenessStatusRetryUntilSuccess()
      .subscribe((response: LivenessStatus) => {
        if (response.status === LivenessStatusResponse.OK) this.pageReload.redirectToHome();
      });

    this.allSubscriptions.push(healthCheckSub);
  }

  navigateToHome(): void {
    this.pageReload.redirectToHome();
  }

  ngOnDestroy(): void {
    for (const subscription of this.allSubscriptions) {
      if (!subscription.closed) {
        subscription.unsubscribe();
      }
    }
  }
}
