import { Theme } from '@themes/models/theme';
import { REGISTRATION_BG_IMAGE } from './variables';

export const DefaultTheme: Theme = {
  name: 'default',
  headerLogo: './assets/themes/default/logos/header-logo.png',
  headerHomeIcon: 'home.png',
  headerInfoIcon: 'info.png',
  payladoHeaderLogo: 'paylado-logo.png',
  cashAcceptorArrowDown: 'cash-acceptor-arrow-down.gif',
  headerLogoIcon: './assets/logo/header-logo.png',
  cssVariables: {
    other: {},
    urls: { [REGISTRATION_BG_IMAGE]: './assets/images/partners-7.png' }
  },
  translationTags: {}
};
