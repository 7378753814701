import { Component, OnInit, HostBinding, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DataShareService } from '@app/shared/services/data-share.service';
import { CustomerService } from '@app/modules/customer/services/customer.service';
import { TerminalDataService } from '@app/shared/services/terminal-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ticker',
  templateUrl: './ticker.component.html',
  styleUrls: ['./ticker.component.scss']
})
export class TickerComponent implements OnInit, OnDestroy {
  @HostBinding('style') style: SafeStyle;
  private casinosSub: Subscription = null;
  selectedStyle: string;
  casinoList;
  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private dataShareService: DataShareService,
    private customerService: CustomerService,
    private terminalDataService: TerminalDataService
  ) {}

  tickerList = [];
  tickerDataLoaded: boolean;

  ngOnInit(): void {
    this.casinosSub = this.dataShareService.casinos.subscribe(res => {
      this.casinoList = res;
      this.casinoList.forEach(cas => {
        this.tickerList.push(cas);
      });

      this.tickerList.forEach(item => {
        const concatTickerName = item.name.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '-');
        item.t_text = 'ticker_' + concatTickerName + '_text';
        item.t_link = 'ticker_' + concatTickerName + '_link';
      });
      const oneTickerDuration = 20;
      const duration = this.tickerList.length * oneTickerDuration;
      const delayed_start = -oneTickerDuration;
      const delay = duration / 2 + delayed_start;

      this.selectedStyle = `
--animation-duration: ${duration}s;
--animation-delay: ${delay}s;
--delayed-start: ${delayed_start}s;
`;

      this.style = this.sanitizer.bypassSecurityTrustStyle(this.selectedStyle);
      if (this.tickerList.length > 1) {
        this.tickerDataLoaded = true;
      } else {
        this.tickerDataLoaded = false;
      }
    });
  }

  saveAndRedirect(offerId: string): void {
    this.dataShareService.setSelectedOffer(offerId);
    this.router.navigate(['app/registration']);
  }

  ngOnDestroy(): void {
    if (this.casinosSub) {
      this.casinosSub.unsubscribe();
    }
  }
}
