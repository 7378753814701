import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProjectConfigService } from '@app/shared/services/project-config.service';
import { DataFetchService } from '@app/shared/services/data-fetch.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { CreditInfo } from '@app/modules/paylado/models/credit-info';
import { CashAcceptorStatus } from '@app/modules/paylado/models/cash-acceptor-status';
import { QrCodeData } from '@app/modules/paylado/models/qr-code-data';
import { TransactionStatus } from '@app/modules/paylado/models/transaction-status';
import { Router } from '@angular/router';
import { AvailableCashData } from '@app/modules/paylado/models/available-bill-data';
import { TransactionQrRequest } from '../models/qr-request';

@Injectable({
  providedIn: 'root'
})
export class PayladoService extends DataFetchService {
  private CashOutButtonStatusSource = new BehaviorSubject(false);
  CashOutButtonStatus = this.CashOutButtonStatusSource.asObservable();

  constructor(public http: HttpClient, public config: ProjectConfigService, private router: Router) {
    super(http, config);
  }

  activateCashAcceptor(): Observable<CashAcceptorStatus> {
    return this.get<CashAcceptorStatus>(`${this.environment.terminalHostUrl}/${this.environment.activateCashAcceptor}`);
  }

  getAvailableCredit(): Observable<CreditInfo> {
    return this.get<CreditInfo>(`${this.environment.terminalHostUrl}/${this.environment.getAvailableCredit}`);
  }

  resetCredit(): Observable<CreditInfo> {
    return this.get<CreditInfo>(`${this.environment.terminalHostUrl}/${this.environment.resetCredit}`);
  }

  deactivateCashAcceptor(): Observable<CashAcceptorStatus> {
    return this.get<CashAcceptorStatus>(
      `${this.environment.terminalHostUrl}/${this.environment.deactivateCashAcceptor}`
    );
  }

  increaseCredit(payload: CreditInfo): Observable<CreditInfo> {
    return this.post<CreditInfo>(`${this.environment.terminalHostUrl}/${this.environment.increaseCredit}`, {
      ...payload
    });
  }

  getQrCodeData(payload: TransactionQrRequest): Observable<QrCodeData> {
    // return this.post<QrCodeData>(`${this.environment.hostUrl}/${this.environment.payladoRequestQrCode}`, {
    //   ...payload
    // });
    return this.post<QrCodeData>(`${this.environment.hostUrlDolphin}/${this.environment.payladoRequestQrCodeDolphin}`, {
      ...payload
    });
  }

  getTransactionStatus(payload = {}): Observable<TransactionStatus> {
    // return this.post<TransactionStatus>(`${this.environment.hostUrl}/${this.environment.payladoRetrieveStatus}`, {
    //   ...payload
    return this.post<TransactionStatus>(
      `${this.environment.hostUrlDolphin}/${this.environment.payladoRetrieveStatusDolphin}`,
      {
        ...payload
      }
    );
  }

  getPayladoPayableAmount(payload = {}): Observable<{ payable_amount: number }> {
    // return this.post<TransactionStatus>(`${this.environment.hostUrl}/${this.environment.payladoRetrievePayable}`, {
    //   ...payload
    // });
    return this.post<TransactionStatus>(
      `${this.environment.hostUrlDolphin}/${this.environment.payladoRetrievePayableDolphin}`,
      {
        ...payload
      }
    );
  }

  cancelTransaction(payload = {}): Observable<TransactionStatus> {
    // return this.post<TransactionStatus>(`${this.environment.hostUrl}/${this.environment.payladoCancelRequest}`, {
    //   ...payload
    // });
    return this.post<TransactionStatus>(
      `${this.environment.hostUrlDolphin}/${this.environment.payladoCancelRequestDolphin}`,
      {
        ...payload
      }
    );
  }

  executeTransaction(payload = {}): Observable<TransactionStatus> {
    // return this.post<TransactionStatus>(`${this.environment.hostUrl}/${this.environment.payladoExecuteTransaction}`, {
    //   ...payload
    // });
    return this.post<TransactionStatus>(
      `${this.environment.hostUrlDolphin}/${this.environment.payladoExecuteTransactionDolphin}`,
      {
        ...payload
      }
    );
  }

  terminalHasPayout(): Observable<boolean> {
    return this.get<boolean>(`${this.environment.terminalHostUrl}/${this.environment.hasPayout}`);
  }

  //    coins
  getHopperData(): Observable<AvailableCashData> {
    return this.get<AvailableCashData>(`${this.environment.terminalHostUrl}/${this.environment.getHopperData}`);
  }

  //    bills
  getRecyclerData(): Observable<AvailableCashData> {
    return this.get<AvailableCashData>(`${this.environment.terminalHostUrl}/${this.environment.getRecyclerData}`);
  }

  startPayout(): Observable<number> {
    return this.post<TransactionStatus>(`${this.environment.terminalHostUrl}/${this.environment.startPayout}`);
  }

  getCashOutProgress(): any {
    return this.specialGet<any>(`${this.environment.terminalHostUrl}/${this.environment.getCashOutProgress}`);
  }

  submitPayoutAmount(payload = {}): Observable<any> {
    return this.post<TransactionStatus>(`${this.environment.hostUrl}/${this.environment.submitPayoutAmount}`, {
      ...payload
    });
  }

  navigateToErrorPage(type: string): void {
    this.router.navigate(['/app/paylado/error'], {
      queryParams: { type: type },
      queryParamsHandling: 'merge'
    });
  }

  enableCashOutButton(value: boolean): void {
    this.CashOutButtonStatusSource.next(value);
  }

  formatAmountWithCurrency(
    amount: number,
    locales = 'de-DE',
    style = 'currency',
    currencyDisplay = 'code',
    currency = 'EUR'
  ): string {
    try {
      return Intl.NumberFormat(locales, {
        style,
        currencyDisplay,
        currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(amount);
    } catch (error) {
      return amount?.toString() || '';
    }
  }
}
