import { Theme } from '@themes/models/theme';
import { REGISTRATION_BG_IMAGE } from './variables';

export const TipwinTheme: Theme = {
  name: 'tipwin',
  headerLogo: './assets/themes/tipwin/logos/header-logo.png',
  headerHomeIcon: 'home-white.png',
  headerInfoIcon: 'info-white.png',
  payladoHeaderLogo: 'paylado-logo.png',
  cashAcceptorArrowDown: 'cash-acceptor-arrow-down.gif',
  headerLogoIcon: './assets/logo/tipwin-logo.png',
  cssVariables: {
    urls: {
      [REGISTRATION_BG_IMAGE]:
        './assets/themes/tipwin/background-images/startscreen-registration-div-background-image.png'
    }
  },
  translationTags: {
    mainpage_polar_text: 'mainpage_polar_text_tipwin',
    mainpage_yellow_info: 'mainpage_yellow_info_tipwin'
  }
};
