import { Injectable } from '@angular/core';
import { BehaviorSubject, interval } from 'rxjs';
import * as moment from 'moment/moment';
import { marker as __ } from '@biesbjerg/ngx-translate-extract-marker';
import { Router } from '@angular/router';
import { NotificationModalComponent } from '@app/shared/components/notifications/notification-modal.component';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ProjectConfigService } from '@app/shared/services/project-config.service';
import { removeTmpSession } from '@app/shared/helpers/auth';
import { NotificationService } from '@app/shared/services/notification.service';
import { ScreenTimeoutPopup } from '../models/popup';
import { PopupService } from './popup.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ScreenTimeoutPopupComponent } from '../components/screen-timeout-popup/screen-timeout-popup.component';
import { HealthCheckService } from './health-check.service';

@Injectable({
  providedIn: 'root'
})
export class PageReloadService {
  screenInactiveTime$ = new BehaviorSubject(0);

  private screenTimeoutConfig: any;
  private lasInteractionTime = new Date();
  private snackBarRef: MatSnackBarRef<NotificationModalComponent>;
  private popupDialogRef: MatDialogRef<ScreenTimeoutPopupComponent>;
  private warningMessage = false;
  private currentPage = 'home';

  constructor(
    private projectConfigService: ProjectConfigService,
    private router: Router,
    private snackBar: MatSnackBar,
    private notificationService: NotificationService,
    private popupService: PopupService,
    private healthCheckService: HealthCheckService
  ) {
    // init config
    this.screenTimeoutConfig = this.projectConfigService.getConfig('screenTimeout');
    // start timeout ticker
    interval(1000).subscribe(() => {
      this.screenInactiveTime$.next(this.checkLastInteractionDifference());
    });
  }

  checkLastInteractionDifference(): any {
    return moment(new Date()).diff(moment(this.lasInteractionTime), 'seconds');
  }

  updateLastInteractionTime(): void {
    this.lasInteractionTime = new Date();
    this.snackBarRef?.dismiss();
    this.popupDialogRef?.close();
    this.snackBarRef?.afterDismissed().subscribe(() => (this.warningMessage = false));
    this.popupDialogRef?.afterClosed().subscribe(() => (this.warningMessage = false));
  }

  setCurrentPage(page: string): void {
    this.currentPage = page;
    this.snackBarRef?.dismiss();
    this.popupDialogRef?.close();
    this.snackBarRef?.afterDismissed().subscribe(() => (this.warningMessage = false));
    this.popupDialogRef?.afterClosed().subscribe(() => (this.warningMessage = false));
  }

  getTimeoutConfig(page = ''): {
    timeOut: number;
    notificationTime: number;
    popupDialog: boolean | undefined;
    notificationCountdown: number | undefined;
  } {
    return this.screenTimeoutConfig.custom?.[page || this.currentPage] || this.screenTimeoutConfig.default;
  }

  handleTimeoutCheck(page: string, time: number): void {
    const { timeOut, notificationTime, popupDialog, notificationCountdown } = this.getTimeoutConfig(page);
    // notify user before we run time out action
    if (notificationTime && time >= notificationTime && !this.warningMessage) {
      if (!popupDialog) this.timeoutSnackBarMessage();
      if (popupDialog) this.timeoutPopupMessage(notificationCountdown);
      this.warningMessage = true;
    }
    // on timeout run page specific action
    if (time >= timeOut) {
      this.snackBarRef?.dismiss();
      this.popupDialogRef?.close();
      this.warningMessage = false;
      this.runTimeoutAction();
    }
  }

  timeoutSnackBarMessage(): void {
    this.snackBarRef = this.notificationService.showMessage(__('screen_timeout_message'), 'error');
  }

  timeoutPopupMessage(notificationCountdown: number): void {
    const timeoutPopupSetting: ScreenTimeoutPopup = {
      data: {
        buttons: {
          close: __('popup_screen_timeout_close_btn')
        },
        cssClass: 'popup__screen-timeout-text',
        content: __('popup_screen_timeout_message_part_1'),
        countdownContent: __('popup_screen_timeout_message_part_2'),
        withTranslation: true,
        screenTimeoutPopup: true,
        countdownTime: notificationCountdown
      },
      width: '50%',
      height: '50%',
      panelClass: 'popup',
      autoFocus: false
    };

    this.popupDialogRef = this.popupService.openScreenTimeoutDialog(timeoutPopupSetting);
  }

  runTimeoutAction(): any {
    return this.currentPage === 'home' ? this.reloadWindow() : this.redirectToHome();
  }

  reloadWindow(): void {
    const isOnline: boolean = this.healthCheckService.getInternetStatus();
    this.updateLastInteractionTime();

    if (isOnline) location.reload();
    removeTmpSession();
  }

  redirectToHome(): void {
    this.router.navigate(['/'], { queryParamsHandling: 'merge' }).finally(() => {
      this.reloadWindow();
    });
  }
}
