import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { ThemeName, ThemeMap, Theme } from '@themes/models/theme';
import { DynamicCssVariables } from '@app/shared/models/DynamicCssVariables';

const LOCAL_STORAGE_THEME_KEY = 'gs_theme';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private themeBehaviorSubject: BehaviorSubject<Theme>;
  private themeObservable: Observable<Theme>;
  private themeSwitchingEnabled = false;

  theme: Theme = ThemeMap.get('default' as ThemeName);

  constructor() {
    this.themeBehaviorSubject = new BehaviorSubject<Theme>(this.theme);
    this.themeObservable = this.themeBehaviorSubject.asObservable();
  }

  getTheme(): Observable<Theme> {
    return this.themeObservable;
  }

  setTheme(themeName: ThemeName): void {
    const theme: Theme = ThemeMap.get(themeName);
    localStorage.setItem('theme', <string>themeName);
    this.theme = theme;
    this.themeBehaviorSubject.next(theme);
    if (this.theme.cssVariables) {
      if (this.theme.cssVariables.other) {
        Object.keys(theme.cssVariables.other).forEach(key => {
          this.setCSSVariable(key, theme.cssVariables.other[key]);
        });
      }
      if (this.theme.cssVariables.urls) {
        Object.keys(theme.cssVariables.urls).forEach(key => {
          const cssVarName = `--${key}`;
          this.setCSSVariable(cssVarName, 'url(' + theme.cssVariables.urls[key] + ')');
        });
      }
    }
  }

  updateTheme(overrides: DynamicCssVariables): void {
    if (!overrides) return;

    if (overrides.URL) {
      Object.entries(overrides.URL).forEach(([key, value]) => {
        const cssVarName = `--${key}`;
        this.setCSSVariable(cssVarName, `url(${value})`);
      });
    }

    if (overrides.TRANSLATION_TAG) {
      this.theme.translationTags = {
        ...this.theme.translationTags,
        ...overrides.TRANSLATION_TAG
      };
    }
    this.themeBehaviorSubject.next(this.theme);
  }

  setCSSVariable(variable: string, value: string): void {
    document.documentElement.style.setProperty(variable, value);
  }

  getThemeTranslationTags(): { [key: string]: string } {
    let translationTags: { [key: string]: string };
    this.getTheme().subscribe(theme => {
      translationTags = theme.translationTags;
    });
    return translationTags;
  }

  getImageSourceUrlByComponentName(componentName: string): Observable<string> {
    return of(this.theme[componentName]);
  }
}
