import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ScreenTimeoutPopupData } from '@app/shared/models/popup';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-screen-timeout-popup',
  templateUrl: './screen-timeout-popup.component.html',
  styleUrls: ['./screen-timeout-popup.component.scss']
})
export class ScreenTimeoutPopupComponent implements OnDestroy {
  private intervalSub: Subscription;
  public timer: number;
  public progressWidth = 100;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ScreenTimeoutPopupData,
    private dialogRef: MatDialog
  ) {
    this.startTimer();
  }

  cancelTimer(): void {
    this.dialogRef.closeAll();
  }

  startTimer(): void {
    if (this.data?.screenTimeoutPopup && this.data?.countdownTime) {
      const notificationCountdownTime = this.data?.countdownTime;
      const intervalTimeInMs = 1000;
      const intervalTimeInS = intervalTimeInMs / 1000;
      const percentageWidth = (intervalTimeInS / notificationCountdownTime) * 100;

      this.timer = notificationCountdownTime;
      this.intervalSub = interval(intervalTimeInMs).subscribe(time => {
        this.timer = notificationCountdownTime - (time + 1);
        this.progressWidth = this.progressWidth - percentageWidth;

        if (Math.trunc(this.progressWidth) <= 0) this.progressWidth = 0;
        if (this.timer === 0 && this.intervalSub) {
          this.intervalSub.unsubscribe();
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.intervalSub) this.intervalSub.unsubscribe();
  }
}
