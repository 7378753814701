import { DefaultTheme } from '@themes/theme.default';
import { MerkurTheme } from '@themes/theme.merkur';
import { TipwinTheme } from '@themes/theme.tipwin';

export interface Theme {
  name?: string;
  headerLogo: string;
  headerLogoIcon?: string;
  headerHomeIcon?: string;
  headerInfoIcon?: string;
  payladoHeaderLogo?: string;
  cashAcceptorArrowDown?: string;
  translationTags?: { [key: string]: string };
  cssVariables?: { urls?: { [key: string]: string }; other?: { [key: string]: string } };
}

export enum ThemeName {
  Default = 'default',
  Merkur = 'merkur',
  Tipwin = 'tipwin'
}

export const ThemeMap = new Map<ThemeName, Theme>([
  [ThemeName.Merkur, MerkurTheme],
  [ThemeName.Tipwin, TipwinTheme],
  [ThemeName.Default, DefaultTheme]
]);
