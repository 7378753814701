// @ts-ignore: ignore the import of package.json from being compiled
import packageInfo from '../../package.json';

export const globalConfig = {
  project: 'vision',
  translationApi: '/translations',
  version: 'v' + packageInfo.version,
  // QuickSilver endpoints
  terminalHostUrl: 'http://localhost:8080/api/Actions',
  terminalSdk: 'sdk',
  terminalSdkFull: 'sdkFull',
  terminalScanData: 'ScanData',
  terminalPrintData: 'Print',
  activateCashAcceptor: 'ActivateCashAcceptor',
  getAvailableCredit: 'GetAvailableCredit',
  resetCredit: 'ResetCredit',
  deactivateCashAcceptor: 'DeactivateCashAcceptor',
  increaseCredit: 'IncreaseCredit',
  hasPayout: 'HasPayout',
  startPayout: 'StartPayout',
  getCashOutProgress: 'GetPayoutStatus',
  getAvailableBills: 'GetAvailableBills',
  getHopperData: 'GetHopperData',
  getRecyclerData: 'GetRecyclerData',
  terminalGetQsVersion: 'GetQsVersion',
  // Wanda endpoints
  auth: 'auth',
  mobileAuth: 'auth/mobile',
  customerLogin: 'customer/login',
  videoIdentStart: 'video-ident/start',
  videoIdentStatus: 'video-ident/status',
  customerInfo: 'customer/info',
  casinosList: 'customer/list-casino-agb',
  tickerCasinosList: 'casino/list',
  addCasinos: 'customer/add-casinos',
  addLimit: 'customer/add-limit',
  checkLimit: 'customer/check-limit',
  loqateFind: 'loqate/find',
  loqateRetrieve: 'loqate/retrieve',
  getCountries: 'country',
  getOccupations: 'occupation',
  getCountriesDialCodes: 'country/dial-code',
  saveCustomer: 'customer/save',
  updateCustomer: 'customer/update',
  customerValidateEmail: 'customer/validateEmail',
  createPayladoAccount: 'customer/add-paylado',
  // createPayladoAccountDolphin: 'customer-service/add-paylado',
  checkPayladoRegistration: 'customer/check-paylado-registration',
  detectGender: 'customer/detect-gender',
  payladoRequestQrCode: 'transaction/request-qr-data',
  payladoRetrieveStatus: 'transaction/retrieve-status',
  payladoCancelRequest: 'transaction/cancel-request',
  payladoExecuteTransaction: 'transaction/execute-request',
  payladoRetrievePayable: 'transaction/retrieve-payable',
  submitPayoutAmount: 'payout/amount',
  retrievePayoutStatus: 'payout/confirm',
  healthCheck: 'healthcheck',
  terminal: 'terminal',
  terminalLastOnlineState: 'terminal/update-online-status',
  otpLogin: 'customer/otp-login',
  otpResend: 'customer/otp-resend',
  otpConfirm: 'customer/otp-confirm',
  // Octopus endpoints
  initAutoIdent: 'mest/sonio/init-verification',
  getSessionAutoIdent: 'mest/sonio/session',
  finishAutoIdent: 'mest/sonio/finish-verification',
  sendSmsVerificationCode: 'mest/sms/send-verification-code',
  checkSmsVerificationCode: 'mest/sms/check-verification-code',

  // Dolphin endpoints
  dolphinInitAutoIdent: 'sonio/create-session',
  dolphinGetSessionAutoIdent: 'sonio/get-session-result',
  dolphinFinishAutoIdent: 'sonio/finish-verification',
  dolphinVisionFeatureFlags: 'vision/feature-flags',
  // Dolphin endpoints
  addCasinosDolphin: 'customer-service/add-casinos',
  saveCustomerDolphin: 'customer/save',
  updateCustomerDolphin: 'customer/update',
  tickerCasinosListDolphin: 'casino/list',
  casinosListDolphin: 'customer/list-casino-agb',
  customerValidateEmailDolphin: 'customer/validate-email',
  customerInfoDolphin: 'customer/info',
  getGendersDolphin: 'gender/translate',
  getCountriesDolphin: 'country',
  terminalDolphin: 'terminal',
  otpLoginDolphin: 'customer/otp-login',
  otpResendDolphin: 'customer/otp-resend',
  otpConfirmDolphin: 'customer/otp-confirm',
  payladoRetrieveStatusDolphin: 'transaction/retrieve-status',
  payladoRetrievePayableDolphin: 'transaction/retrieve-payable',
  payladoCancelRequestDolphin: 'transaction/cancel-request',
  payladoExecuteTransactionDolphin: 'transaction/execute-request',
  terminalLastOnlineStateDolphin: 'terminal/update-online-status',
  payladoRequestQrCodeDolphin: 'transaction/request-qr-data',
  getCountriesDialCodesDolphin: 'country/dial-code',
  getOccupationsDolphin: 'occupation',
  streetWearQrCode: 'device-detector',
  authDolphin: 'auth',
  mobileAuthDolphin: 'auth/mobile',
  translationApiDolphin: '/translation',
  locale: 'de-DE',
  help: {},
  languages: [
    {
      iso: 'de',
      name: 'Deutsch',
      languageId: 1
    },
    {
      iso: 'en',
      name: 'English',
      languageId: 2
    },
    {
      iso: 'es',
      name: 'Español',
      languageId: 3
    },
    {
      iso: 'tr',
      name: 'Türkce',
      languageId: 4
    },
    {
      iso: 'nl',
      name: 'Nederlands',
      languageId: 5
    }
  ],
  screenTimeout: {
    default: {
      timeOut: 60 * 10,
      notificationTime: 60 * 9
    },
    custom: {
      home: {
        timeOut: 60 * 5,
        notificationTime: 0
      },
      registration: {
        timeOut: 60 * 5,
        notificationTime: 60 * 5 - 15,
        notificationCountdown: 15,
        popupDialog: true
      }
    }
  }
};
