import { Theme } from '@themes/models/theme';

export const MerkurTheme: Theme = {
  name: 'merkur',
  headerLogo: './assets/themes/merkur/logos/header-logo.png',
  headerHomeIcon: 'home.png',
  headerInfoIcon: 'info.png',
  payladoHeaderLogo: 'paylado-logo.png',
  cashAcceptorArrowDown: 'cash-acceptor-arrow-down.gif',
  headerLogoIcon: './assets/logo/header-logo.png'
};
