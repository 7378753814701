import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import QRCode from 'qrcode';
@Component({
  selector: 'app-dialog-streetwear',
  templateUrl: './dialog-streetwear.component.html',
  styleUrls: ['./dialog-streetwear.component.scss']
})
export class DialogStreetwearComponent implements AfterViewInit {
  @ViewChild('qrCodeContainer', { static: false }) qrCodeContainer: ElementRef;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      qrCodeUri: string;
    }
  ) {}

  ngAfterViewInit(): void {
    this.generateQrCode(this.data.qrCodeUri);
  }

  generateQrCode(qrCodeUri: string): void {
    QRCode.toCanvas(qrCodeUri, { width: 150 }, (error, canvas) => {
      if (error) {
        console.error(error);
      } else {
        this.qrCodeContainer.nativeElement.innerHTML = '';
        this.qrCodeContainer.nativeElement.appendChild(canvas);
      }
    });
  }
}
