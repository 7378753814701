import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppShellComponent } from '@app/modules/layout/components/app-shell/app-shell.component';
import { AuthGuard } from '@app/shared/guard/auth.guard';
import { DashboardComponent } from '@app/modules/layout/components/dashboard/dashboard.component';
import { SoftwareUpdateComponent } from '@app/modules/software-update/software-update.component';
import { AppComponent } from '@app/app.component';
import { WelcomePageComponent } from '@app/modules/layout/components/welcome-page/welcome-page.component';
import { ServerIsNotAvailableErrorPageComponent } from './modules/server-is-not-available-error-page/server-is-not-available-error-page.component';
import { MaintenanceModePageComponent } from './modules/maintenance-mode-page/maintenance-mode-page.component';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    data: { page: 'app' }
  },
  {
    path: 'home',
    component: DashboardComponent,
    data: { page: 'home' }
  },
  {
    path: 'welcome',
    component: WelcomePageComponent,
    data: { page: 'welcome' }
  },
  {
    path: 'app',
    component: AppShellComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'registration',
        loadChildren: () => import('./modules/registration/registration.module').then(m => m.RegistrationModule),
        data: { page: 'registration', screenInactiveResetTimer: true }
      },
      {
        path: 'customer',
        loadChildren: () => import('./modules/customer/customer.module').then(m => m.CustomerModule),
        canActivate: [AuthGuard],
        data: { page: 'customer' }
      },
      {
        path: 'paylado',
        loadChildren: () => import('./modules/paylado/paylado.module').then(m => m.PayladoModule),
        canActivate: [AuthGuard],
        data: { page: 'paylado' }
      }
    ]
  },
  {
    path: 'software-update',
    component: SoftwareUpdateComponent
  },
  {
    path: 'server-is-not-available',
    component: ServerIsNotAvailableErrorPageComponent
  },
  {
    path: 'maintenance-mode',
    component: MaintenanceModePageComponent
  },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
