import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-notification-action',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent implements OnInit {
  constructor(
    public snackBarRef: MatSnackBarRef<NotificationModalComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}

  ngOnInit(): void {
    // hide notification modal if message property is missing
    if (!this.data.message) {
      this.snackBarRef.dismiss();
      // console.error('ERROR: NotificationModalComponent "message" property is required!');
    }
  }
}
